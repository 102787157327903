import {AfterContentInit, Component, ContentChildren, Input, Output, QueryList, ViewChild, EventEmitter} from '@angular/core';
import {MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable} from '@angular/material/table';

export interface TableColumn {
  name: string;
  title: string;
  custom?: boolean;
  filter?: string;
  width?: number;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class AppTableComponent<TModel> implements AfterContentInit {
  @Input() public columns: TableColumn[] = [];
  @Input() public dataSource!: any;
  @Input() public caption!: string;
  @Input() public class!: string;
  @Output() public contentChanged: EventEmitter<void> = new EventEmitter<void>();
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
  @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<TModel>>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
  @ViewChild(MatTable, {static: true}) table!: MatTable<TModel>;

  public get defaultColumns(): TableColumn[] {
    return this.columns.filter((column) => !column.custom);
  }

  public get displayedColumns(): string[] {
    return this.columns.map((column) => column.name);
  }

  public ngAfterContentInit(): void {
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach((rowDef) => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach((headerRowDef) => this.table.addHeaderRowDef(headerRowDef));
  }
}
