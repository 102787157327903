import {Pipe, PipeTransform} from "@angular/core";

import {BidInterface} from "@layouts/pages/lots/shared/interfaces/bid.interface";

@Pipe({
  name: 'bidsFilter'
})
export class BidsFilterPipe implements PipeTransform{
  transform(value: BidInterface[]): BidInterface[] {
    let resulArray: BidInterface[] = [];
    const usersIdArray = value.reduce((acc: number[], item) => {
      const userId: number = item.user_id;
      const isHaveId = acc.find((item) => item === userId);

      if (acc.length && isHaveId) {
        return acc;
      } else {
        acc.push(item.user_id);
        return acc;
      }
    }, []);

    usersIdArray.forEach((item) => {
      const idLastBidOfUser = value
        .filter((userBid) => userBid.user_id === item)
        .reduce((acc, bid) => bid.id > acc ? acc = bid.id : acc, 0);
      const lastBid = value.find((userBid) => userBid.id === idLastBidOfUser);

      if (lastBid) {
        resulArray.push(lastBid);
      }
    });
    resulArray.sort((a, b) => a.amount < b.amount ? 1 : -1)

    return resulArray;
  }

}
