import {Pipe, PipeTransform} from "@angular/core";
import {BidInterface} from "@layouts/pages/lots/shared/interfaces/bid.interface";

@Pipe({
  name: 'bidsSortPipe'
})
export class BidsSortPipe implements PipeTransform {
  transform(value: Array<BidInterface>): Array<BidInterface> {
    return value.sort((a: BidInterface, b: BidInterface): number => {
      return new Date(b.created_at) === new Date(a.created_at) ? 0 :
        (new Date(b.created_at) > new Date(a.created_at) ? 1 : -1);
    });
  }
}
