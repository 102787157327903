import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusPipe} from './status.pipe';
import {DetailsExtractPipe} from '@app/layouts/pages/lots/shared/pipes/details-extract.pipe';
import {BidsFilterPipe} from '@app/layouts/pages/lots/shared/pipes/bids-filter.pipe';
import {BidsSortPipe} from '@app/layouts/pages/lots/shared/pipes/bids-sort.pipe';

const PIPES = [StatusPipe, DetailsExtractPipe, BidsFilterPipe, BidsSortPipe];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  exports: [...PIPES],
})
export class PipesModule {}
