import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'formly-picture-uploader',
  template: `
    <span style="margin-right: 10px">{{ props.label }}</span>
    <input [formlyAttributes]="field" [hidden]="true" type="file" [formControl]="formControl">
    <input [hidden]="true" type="file" (change)="props['fileUpload'](pdfInput.files)"
           accept="image/png, image/jpeg, image/webp" #pdfInput/>
    <div class="images-container">
      <div class="images-actions-wrapper">
        <div class="images-actions">
          <button mat-icon-button class="images-actions-item" (click)="props['deleteMainPhoto']()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <img class="images-item" [src]="defineSrc()" (click)="pdfInput.click()" alt="Фото">
      </div>
    </div>
  `,
  styles: [
    `
      .images {
        &-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        &-item {
          width: 150px;
          height: 150px;
          border: 1px solid gray;
          border-radius: 5px;
          cursor: pointer;
          object-fit: cover;

          &:hover {
            opacity: 0.7;
            border: 1px solid red;
          }
        }

        &-actions {
          position: absolute;
          left: 50px;
          top: 0;
          display: none;

          &-item {
            z-index: 11;
            color: white;
            border: 1px solid white;
            border-radius: 50%;
          }

          &-wrapper {
            z-index: 10;
            border-radius: 5px;
            position: relative;
            width: 150px;
            height: 150px;

            &:hover {
              background-color: gray;
              opacity: 1;

              .images-item {
                opacity: 0.3;
              }

              .images-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    `,
  ],
})
export class FormlyPictureUploader extends FieldType<FieldTypeConfig> {
  public defaultImage: string = 'assets/img/add_img.png';

  defineSrc(): string {
    if (this.formControl.value && this.formControl.value[0]) {
      return this.formControl.value[0].original_url;
    }
    return this.defaultImage;
  }
}

