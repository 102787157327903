import {Pipe, PipeTransform} from "@angular/core";
import {StatusPipe} from "@pipes/status.pipe";
import {
  FUNCTIONAL_EQUIPMENT_TYPE,
  SECURITY_TYPE,
  EXTERIOR_OPTIONS,
  SIGNAL_TYPE,
  REGULATIONS_TYPE,
  MULTIMEDIA_TYPE,
  INTERIOR_TYPE,
  INTERIOR_OPTIONS,
  INTERIOR_COLOR,
  CLIMATE_CONTROL_TYPE,
  STEERING_WHEEL_TYPE,
  GEAR_TYPE,
  ENGINE_TYPE,
  TRANSMISSION_TYPE,
  PTS_TYPE,
  AUCTION_CATEGORY,
  AUCTION_TYPE
} from "@shared/constants";

@Pipe({name: 'extract'})
export class DetailsExtractPipe implements PipeTransform {
  constructor(private statusPipe: StatusPipe) {
  }

  transform(value: any, key: string | string[], returnType: string = 'string'): any {
    if (!value && Object.keys(value).length === 0) {
      return ['Нет данных'];
    }

    let el;
    let originalKeys: string[] = [];

    typeof key == 'string' ? (originalKeys = [key], el = value[key]) : (originalKeys = [...key], el = this.getElement(value, key));

    switch (returnType) {
      case 'string':
        return this.transformToString(el, originalKeys);
      case 'custom':
        return this.transformToArray(el, originalKeys);
    }
  }

  private transformToArray(value: any, key: string | string[]) {
    let itemKey;

    if (Array.isArray(key)) {
      key.at(-1) == 'title' ? itemKey = key.at(-2) : itemKey = key.at(-1);
    }

    if (value == undefined) {
      return [{title: 'Нет данных', value: ''}];
    }

    switch (itemKey) {
      case 'interior':
        return INTERIOR_OPTIONS.map(option => {
          const match = INTERIOR_TYPE.find(item => item.id == value[option.id])
            ? INTERIOR_TYPE.find(item => item.id == value[option.id])
            : INTERIOR_COLOR.find(item => item.id == value[option.id]);
          return {title: option.title, value: match?.title || 'Нет данных'};
        });
      case 'exterior':
        return EXTERIOR_OPTIONS.map(option => {
          return {title: option.title, value: value[option.id] ? 'Да' : 'Нет'};
        });
      case 'main_photos':
        return value.map((item: any) => item.original_url);
      case 'photos':
        return value.map((item: any) => item.original_url);
      case 'comfort_ids':
        return EXTERIOR_OPTIONS.map(option => {
          return {title: option.title, value: value[option.id] ? 'Да' : 'Нет'};
        });
      case 'regulations':
        return REGULATIONS_TYPE.map(option => {
          return {title: option.title, value: value[option.id] ? 'Да' : 'Нет'};
        });
      case 'multimedia':
        return MULTIMEDIA_TYPE.map(option => {
          return {title: option.title, value: value[option.id] ? 'Да' : 'Нет'};
        });
      case 'security':
        return SECURITY_TYPE.map(option => {
          return {title: option.title, value: value[option.id] ? 'Да' : 'Нет'};
        });
      case 'functional_equipment':
        return FUNCTIONAL_EQUIPMENT_TYPE.map(option => {
          return {title: option.title, value: value[option.id] ? 'Да' : 'Нет'};
        });
    }
    return [{title: 'Нет данных', value: ''}];
  }

  private transformToString(value: any, key: string | string[]) {
    if (Array.isArray(key)) {
      if (key.at(-1) == 'title') {
        return value;
      }
    }

    if (value == undefined) {
      return 'Нет данных';
    }

    const itemKey = key.at(-1);

    switch (itemKey) {
      case 'status':
        return this.statusPipe.transform(value);
      case 'gear':
        return this.findMatch(GEAR_TYPE, value);
      case 'engine_type':
        return this.findMatch(ENGINE_TYPE, value);
      case 'transmission':
        return this.findMatch(TRANSMISSION_TYPE, value);
      case 'pts':
        return this.findMatch(PTS_TYPE, value);
      case 'interior_type':
        return this.findMatch(INTERIOR_TYPE, value);
      case 'interior_color':
        return this.findMatch(INTERIOR_COLOR, value);
      case 'exterior':
        return this.findMatch(EXTERIOR_OPTIONS, value);
      case 'signaling':
        return this.findMatch(SIGNAL_TYPE, value);
      case 'climate_control':
        return this.findMatch(CLIMATE_CONTROL_TYPE, value);
      case 'steering_wheel':
        return this.findMatch(STEERING_WHEEL_TYPE, value);
      case 'auction_category':
        return this.findMatch(AUCTION_CATEGORY, value);
      case 'auction_type':
        return this.findMatch(AUCTION_TYPE, value);
    }
    return value;
  }

  private getElement(value: any, keys: string[]): any {
    if (!value) {
      return "";
    }

    let key: string = keys.shift() || '';
    return keys.length ? this.getElement(value[key], keys) : value[key];
  }

  private findMatch(
    constant: ({ id: string; title: string; } | { id: null; title: string; })[],
    value: any) {

    if (value) {
      const val = typeof value == 'string' ? value : value.id;
      return constant.find(item => item.id == val)?.title || 'Нет данных';
    }

    return 'Нет данных';
  }
}
